/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import emailjs from "emailjs-com";
import { useState } from "react";
// import CalendlyButton from "layouts/sections/components/CalendlyButton";
// import { ButtonGroup } from "@mui/material";

// Env Var
// require("dotenv").config();

function Contact() {
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        `service_0oz7cfb`,
        `template_30lyae9`,
        e.target,
        `8ug2-oexG5Y-rYBAI`
      )
      .then(() => {
        setEmailSent(true);
        setTimeout(() => {
          setEmailSent(false);
        }, 5000);
      });
    return;
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact",
            label: "Schedule Session",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={3}>
          {emailSent && (
            <MKAlert color="success" dismissible="true">
              <CheckIcon fontSize="small">check</CheckIcon>&nbsp; Your message
              was sent!
            </MKAlert>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        {/* LEFT SIDE  */}
        {/*  <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 15 }}
          mr={{ xs: "auto", lg: 15 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Schedule a session with Jerry
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3} align="center">
                30 minute introduction session
              </MKTypography>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                mt={5}
                mb={2}
              >
                 <CalendlyButton url="https://calendly.com/coachingbyjerry/inpersonintro" buttonText="In Person" />
                <CalendlyButton url="https://calendly.com/coachingbyjerry/zoom-introduction-meeting" buttonText="Zoom" /> 
              </Grid>
              <hr />
              <br />
              <br />
              <MKTypography variant="body2" color="text" mb={3} align="center">
                60 minute regular coaching session
              </MKTypography>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                mt={5}
                mb={2}
              >
                 <CalendlyButton url="https://calendly.com/coachingbyjerry/in-person-coaching-session" buttonText="In Person" />
                <CalendlyButton url="https://calendly.com/coachingbyjerry/zoom-coaching-session" buttonText="Zoom" /> 
              </Grid>
            </MKBox>
          </MKBox>
        </Grid> */}

        {/* RIGHT SIDE */}

        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 15 }}
          mr={{ xs: "auto", lg: 15 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Send Jerry an email
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including coaching opportunities, please
                email jvanauken01@gmail.com or contact using our contact form.
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autocomplete="off"
                onSubmit={sendEmail}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      name="from_name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      // onChange={handleNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      name="from_email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      // onChange={handleEmailChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="What would you like to send?"
                      placeholder="Ask for pricing details, session availability, or more details about the coaching process."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      name="message"
                      // onChange={handleMessageChange}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  mt={5}
                  mb={2}
                >
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Contact;
