/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
// import MKButton from "components/MKButton";

function Quotes() {
  const [quotes, setQuotes] = useState({ reviews: [] });

  useEffect(() => {
    async function getReviews() {
      await fetch("reviews.json")
        .then((r) => r.json())
        .then((data) => {
          setQuotes(data);
        });
    }

    getReviews();
  }, []);

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Reviews about Jerry&apos;s Coaching
          </MKTypography>
        </Grid>
        {/* <MKButton
          component={Link}
          to={"/testimonies"}
          variant={"contained"} // "gradient"
          color={"info"}
          size="small"
          >
          Read full Testimonies here
          </MKButton>
          <br />
        <br /> */}
        <Grid container spacing={3}>
          {quotes.reviews.map((quote) => {
            return (
              <Grid item xs={12} sm={6} lg={3}>
                <DefaultReviewCard
                  color="info"
                  name={quote.name}
                  date={quote.date}
                  review={quote.review}
                  showButton={quote.showButton}
                  buttonLink={quote.buttonLink}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Quotes;
