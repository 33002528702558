// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import cml from "assets/images/logos/Coaching Mastery Logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Coaching By Jerry",
    image: cml,
    route: "/",
  },
  socials: [],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}. Made with ❤️ by{" "}
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Joel Van Auken
      </MKTypography>
      .
    </MKTypography>
  ),
};
