/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/JerryVanAuken.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -20, md: -25 }} textAlign="center">
            <MKAvatar
              src={profilePicture}
              alt="Jerry Van Auken"
              size="xxxl"
              shadow="xxl"
            />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <MKTypography variant="h3">Jerry Van Auken</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                Jerry Van Auken is a Life Coach who has been professionally
                trained by{" "}
                <a href="https://www.creativeresultsmanagement.com/">
                  Creative Results Management
                </a>{" "}
                through their{" "}
                <a href="https://www.creativeresultsmanagement.com/mastery">
                  Coaching Mastery Certificate Program
                </a>
                . Jerry is working towards credentialing with the International
                Coaching Federation (ICF).
                <br />
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
