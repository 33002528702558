/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
// import bgImage from "assets/images/city-profile.jpg";

function Testimonies() {
  const [quotes, setQuotes] = useState({ reviews: [] });

  useEffect(() => {
    async function getReviews() {
      await fetch("reviews.json")
        .then((r) => r.json())
        .then((data) => {
          setQuotes(data);
        });
    }

    getReviews();
  }, []);

  return (
    <>
      <MKBox position="absolute" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact",
            label: "Schedule Session",
            color: "info",
          }}
        />
      </MKBox>
      <MKBox
        component="section"
        position="absolute"
        top="8rem"
        py={{ xs: 6, sm: 12 }}
      >
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container item justifyContent="center">
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-8}
            >
              <MKTypography variant="h1" color="white">
                Testimonies
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid container spacing={3}>
            <Grid
              container
              item
              spacing={6}
              xs={12}
              justifyContent="left"
              mx="auto"
            >
              <Grid container spacing={6} justifyContent="left" py={6}>
                {quotes.reviews.map((quote) => {
                  return (
                    <Grid item xs={12} sm={10} lg={6}>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="left"
                        mb={1}
                        id={quote.buttonLink}
                      >
                        <MKTypography variant="h3">{quote.name}</MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                      >
                        {quote.showButton ? quote.reviewLong : quote.review}
                        <br />
                      </MKTypography>
                    </Grid>
                  );
                })}
                {/* end */}
              </Grid>
            </Grid>
          </Grid>
          {/* </MKBox> */}
        </Card>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
    // </MKBox>
  );
}

export default Testimonies;
